<template>
  <div class="notification">
    <h3 class="title is-3 is-bold">Kostenrechner Vermietung</h3>

    <div class="columns">
      <div class="column is-6">
        <b-field label="Organisation">
          <!-- todo: portal 2.0 einbauen -->
          <b-radio-button v-model="enquiry.rent.type" native-value="school"
            type="is-primary is-light is-outlined" :disabled="is_loading">
            <b-icon icon="school-outline"></b-icon>
            <span>Schule</span>
          </b-radio-button>

          <b-radio-button v-model="enquiry.rent.type" native-value="company" type="is-primary is-light is-outlined"
            :disabled="is_loading">
            <b-icon icon="domain"></b-icon>
            <span>Firma</span>
          </b-radio-button>

          <b-radio-button v-model="enquiry.rent.type" native-value="private" type="is-primary is-light is-outlined"
            :disabled="is_loading">
            <b-icon icon="account"></b-icon>
            <span>Privatperson</span>
          </b-radio-button>
        </b-field>

        <b-field label="Mietzeitraum">
          <b-datepicker placeholder="Von Datum" icon="calendar-today" :locale="locale"
            v-model="enquiry.rent.dates.date_from" :disabled="is_loading" editable>
          </b-datepicker>
          <b-datepicker placeholder="Bis Datum" icon="calendar-today" :locale="locale"
            v-model="enquiry.rent.dates.date_to" :disabled="is_loading" editable>
          </b-datepicker>
        </b-field>

        <b-field label="Anzahl Dominosteine">
          <b-select placeholder="Anzahl Dominosteine" v-model="enquiry.rent.amount_boxes"
            v-on:input="handleBoxAmountSwitch" :disabled="is_loading">
            <option v-for="option in domino_amount_options" :value="option.val" :key="option.val">
              {{ option.display }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Transport Dominosteine">
          <b-radio-button v-model="enquiry.rent.transport" native-value="pickup" type="is-primary is-light is-outlined"
            :disabled="is_loading">
            <b-icon icon="dolly"></b-icon>
            <span>Abholung</span>
          </b-radio-button>

          <b-radio-button v-model="enquiry.rent.transport" native-value="delivery"
            type="is-primary is-light is-outlined" :disabled="is_loading">
            <b-icon icon="truck-delivery"></b-icon>
            <span>Lieferung</span>
          </b-radio-button>

          <b-tooltip label="Postversand bis zu 6'000 Dominos" type="is-primary is-light" position="is-top"
            :animated="false">
            <b-radio-button v-model="enquiry.rent.transport" native-value="shipping"
              type="is-warning is-light is-outlined" :disabled="!is_shipping_available || is_loading"
              title="Postversand bis zu 6'000 Dominos">
              <b-icon icon="package-variant"></b-icon>
              <span>Post</span>
            </b-radio-button>
          </b-tooltip>
        </b-field>

        <div v-if="is_delivery_selected" style="margin-bottom: 10px;">
          <p class="is-size-6">Wir liefern Ihnen Ihre gemieteten Dominosteine bequem an Ihre Zieladresse und holen diese
            nach abgeschlossener Vermietung wieder ab.</p>
          <b-field label="Distanz von Zieladresse und Lageradresse Hefenhofen TG in Km">
            <b-numberinput v-model="enquiry.rent.distance" placeholder="99" :min="0" :max="250" step="1"
              type="is-outlined" controls-position="compact" :disabled="is_loading"></b-numberinput>
          </b-field>
          <p class="is-size-7">*Die Distanz von der Lageradresse zur Zieladresse wird für die Berechnung der
            Transportkosten benötigt.</p>
        </div>

        <p class="is-size-6" v-else-if="is_shipping_selected">
          Wir senden Ihnen bis zu <b>maximal 6'000 Dominosteinen</b> mit der Schweizerischen Post an Ihre Zieladresse.
          Nach abgeschlossener Vermietung können Sie diese ebenfalls per Post an uns zurückschicken. Die Rückversandkosten trägt der Kunde.
        </p>

        <p class="is-size-6" v-else>
          Die Dominosteine werden an dem von Ihnen ausgewählten Standort abholbereit deponiert.

          <b-field label="Verfügbare Abholstandorte" style="margin-top: 10px;">
            <div class="notification">
            <b-loading :is-full-page="false" v-model="loading_shown_for_pickup_locations" :can-cancel="false" v-if="!pickup_locations"></b-loading>
            <b-field v-for="place in pickup_locations" :key="place.id">
              <b-radio v-model="enquiry.rent.pickup_location_id" :native-value="place.id" size="is-default">
                <p><b>{{place.location.name}}</b>&nbsp;,
                <a :href="place.location.url" target="_blank" title="Adresse in Google-Maps öffnen" class="has-text-link">{{place.location.plz + ' ' + place.location.place + ' ('+place.location.canton+')'}}</a> 
                (bis {{place.units / 1000}} Boxen)</p>
              </b-radio>
             </b-field>
            </div>
        </b-field>
        </p>

        <p class="is-size-6" v-if="!is_pickup_selected">
          <b>Lageradresse:</b><br>
          <Address />
        </p>

        <b-field
          label="Zugang zum Online-Portal gefüllt mit Tipps & Tricks, Unterlagen und Inspirationen freischalten." v-show="portalAccessChoosable">
          <b-tooltip label=" Zugang für das Portal freischalten." position="is-bottom" type="is-success"
            :animated="false">
            <b-radio-button v-model="enquiry.rent.portal_access" native-value="requested"
              type="is-success is-light is-outlined" :disabled="is_loading">
              <b-icon icon="check"></b-icon>
              <span>Ja</span>
            </b-radio-button>
          </b-tooltip>

          <b-tooltip label="Keinen Zugang für das Portal freischalten." type="is-danger" position="is-bottom"
            :animated="false">
            <b-radio-button v-model="enquiry.rent.portal_access" native-value="none"
              type="is-warning is-light is-outlined" :disabled="is_loading">
              <b-icon icon="close-circle-outline"></b-icon>
              <span>Nein</span>
            </b-radio-button>
          </b-tooltip>
          <b-button label="Was ist das?" type="is-info is-outlined" icon-left="help-box" @click="open_portal_modal" />
          <PortalAd :ad_shown="false" :modal_shown="portal_modal_shown" :close_action="close_portal_modal"
            :get_access_action="set_portal_access_true" />
        </b-field>

        <div v-if="!is_voucher_field_shown" style="margin-bottom: 10px;" class="is-size-6">
          <a @click="is_voucher_field_shown = true">Vergünstigungscode einlösen</a>
        </div>
        <div v-else>
          <b-field label="Vergünstigungscode">
            <b-input v-model="enquiry.rent.voucher" maxlength="6" type="is-outlined" :disabled="is_loading"
              icon="barcode"></b-input>
            <p class="control" />
          </b-field>
        </div>

        <div class="notification is-warning" v-if="error">
          <p class="is-size-6">{{error}}</p>
        </div>

        <button class="button is-outlined is-primary is-medium" v-bind:class="{'is-loading':is_loading}"
          @click="calculate">Kosten
          berechnen</button>

        <div class="notification is-danger" v-if="is_error_occured">
          <p>Beim Berechnen der Vermietung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</p>
        </div>
        <br>

        <br>
      </div>
      <div class="column is-6">
        <h5 class="title is-5">Kostenberechnung</h5>
        <div class="tile is-ancestor">
          <div class="tile is-5 is-vertical is-parent">
            <div class="tile is-child box" v-bind:class="{'hidden' : is_loading}">
              <p class="title is-loading">CHF {{dashOrPrice(rent_price_dominoes)}}</p>
              <p class="subtitle">Miete Dominosteine</p>
            </div>
            <div class="tile is-child box notification is-dark" v-bind:class="{'hidden' : is_loading}">
              <p class="title">CHF {{dashOrPrice(rent_price_transport)}}</p>
              <p class="subtitle">Transport</p>
            </div>
          </div>
          <div class="tile is-vertical is-parent">
            <div class="tile is-12 is-vertical box notification is-orange"
              v-bind:class="{'hidden' : is_loading || !is_portal_access_calculated}">
              <p class="title">CHF {{dashOrPrice(rent_price_portal)}}</p>
              <p class="subtitle">Zugang Portal</p>
            </div>
            <div class="tile is-child box notification is-success"
              v-bind:class="{'hidden' : is_loading || !is_voucher_code_present}">
              <p class="title">CHF {{dashOrPrice(rent_price_voucher)}}</p>
              <p class="subtitle">Reduktion</p>
            </div>
          </div>
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-vertical is-parent">
            <div class="tile is-child box notification is-primary" v-bind:class="{'hidden' : is_loading}">
              <p class="title">CHF {{dashOrPrice(rent_price_sum)}}</p>
              <p class="subtitle">Gesamtkosten</p>
              <button class="button is-primary is-inverted is-fullwidth" :disabled="rentEnquiryButtonDisabled"
                @click="handleMakeEnquiry">Vermietung anfragen</button>
              <p class="is-size-7" style="margin-top: 10px;">
                * Sie erhalten nach Überprüfung Ihrer Angaben eine entsprechende Offerte.
              </p>
            </div>
          </div>
        </div>

        <div class="tile is-12 is-vertical box notification is-warning" v-if="rent_unavailable_error">
          <p class="title is-4">Nicht genügend Dominosteine verfügbar</p>
          <p class="subtitle" v-html="rent_unavailable_error"></p>
        </div>
      </div>

      <FillInForm :shown="is_enquiry_submit_form_shown" :is_loading="is_enquiry_submit_form_loading" />

    </div>
    <div class="notification is-white">
      <h4 class="title is-5">Probleme mit dem Kostenrechner?</h4>
      <p>Gerne nehmen wir Anfragen auch per <router-link :to="{name: 'kontakt'}">Kontaktformular</router-link> oder <a
          href="mailto:info@swissdominoeffect.com">Mail</a> entgegen.</p>
    </div>
  </div>
</template>
<style>
.hidden {
  opacity: 0.4;
}

.is-orange {
  color: white;
  background-color: orange;
}
</style>
<script>
import { requestRent, submitRent, loadPickupLocations } from "@/webapi";
import FillInForm from "./../FillInForm";
import PortalAd from "./../PortalAd";
export default {
  data: function () {
    return {
      enquiry: {
        rent: {
          type: "school",
          amount_boxes: 10,
          dates: {
            date_from: null,
            date_to: null,
          },
          transport: "pickup",
          pickup_location_id: null,
          portal_access: "requested",
          distance: 0,
          voucher: null,
        },
      },
      is_loading: false,
      resp: null,
      locale: "de-DE",
      is_enquiry_submit_form_shown: false,
      is_enquiry_submit_form_loading: false,
      changed: false,
      error: "",
      portal_modal_shown: false,
      is_voucher_field_shown: false,
      pickup_locations: null,
    };
  },
  watch: {
    enquiry: {
      handler: function (oldObj, newObj) {
        this.detectChange();
      },
      deep: true,
    },
  },
  created() {
    this.loadPickupLocationsFromServer();
    let voucherParameter = this.$route.query.voucher;
    if (voucherParameter) {
      this.enquiry.rent.voucher = voucherParameter;
      this.is_voucher_field_shown = true;
    }
    let initialType = this.initialTypeSelection;
    if (initialType && initialType == "company") {
      this.enquiry.rent.type = initialType;
    }
  },
  methods: {
    loadPickupLocationsFromServer() {
      loadPickupLocations().then((resp) => {
        if (resp.data) {
          this.pickup_locations = resp.data;
          
        }
      });
    },
    calculate() {
      this.validateRentRequest();
      if (!this.error) {
        this.is_loading = true;
        requestRent({
          enquiry: this.copyEnquiry(),
        })
          .then((resp) => {
            this.is_loading = false;
            this.resp = resp.data;
            this.changed = false;
          })
          .catch((error) => {
            this.is_loading = false;
            this.error = error;
          });
      }
    },
    dashOrPrice(price) {
      return price > 0 ? Math.floor(price) : "-";
    },
    handleMakeEnquiry() {
      this.is_enquiry_submit_form_shown = true;
    },
    closeSubmitForm() {
      this.is_enquiry_submit_form_shown = false;
    },
    handleBoxAmountSwitch() {
      if (this.is_shipping_selected && !this.is_shipping_available) {
        this.enquiry.rent.transport = "delivery";
      }
    },
    validateRentRequest() {
      this.error = "";

      let fromDate = this.enquiry.rent.dates.date_from;
      let toDate = this.enquiry.rent.dates.date_to;
      if (!fromDate || !toDate) {
        this.error = "Bitte wählen Sie einen korrekten Mietzeitraum.";
        return;
      } else if (new Date(fromDate).getTime() > new Date(toDate).getTime()) {
        this.error =
          "Der Beginn des Mietzeitraum muss kleiner als das Enddatum sein.";
        return;
      } else if (new Date(fromDate).getTime() < new Date().getTime()) {
        this.error = "Der angegebene Mietzeitraum muss in der Zukunft liegen.";
        return;
      } else if (
        this.enquiry.rent.transport == "delivery" &&
        this.enquiry.rent.distance == 0
      ) {
        this.error =
          "Bitte geben Sie die ungefähre Distanz zwischen unserer Lageradresse und der Zieladresse an.";
        return;
      }
      // portal always requested for companies
      if (!this.portalAccessChoosable) {
        this.enquiry.rent.portal_access = "requested";
      }

      if (
        this.is_pickup_selected &&
        (this.enquiry.rent.pickup_location_id == null ||
          this.enquiry.rent.pickup_location_id == "")
      ) {
        this.error = "Bitte wählen Sie einen Abholstandort aus.";
        return;
      }

      // no pickup location id for rent requests without pickup option
      if (!this.is_pickup_selected) {
        this.enquiry.rent.pickup_location_id = null;
      }
    },
    copyEnquiry() {
      let copy = JSON.parse(JSON.stringify(this.enquiry));
      copy.rent.dates.date_from = this.convertDate(copy.rent.dates.date_from);
      copy.rent.dates.date_to = this.convertDate(copy.rent.dates.date_to);
      return copy;
    },
    convertDate(rawDate) {
      if (rawDate.toString().includes("T")) {
        let date = new Date(rawDate);
        let dateCorrected = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        );
        return dateCorrected.toISOString().split("T")[0];
      }
      return rawDate;
    },
    handleSubmitForm(client, addition) {
      // set organisation type
      client.organization_type = this.enquiry.rent.type;

      let type = addition.workshop_interest ? "workshop" : "rent";
      let rent = {
        costs: {
          positions: this.getCostPositions(addition.workshop_interest),
        },
        customer: client,
        info: {
          dates: this.enquiry.rent.dates,
          description: addition.description,
          type: type,
          domino_count: this.enquiry.rent.amount_boxes * 1000,
          pickup_location_id: this.enquiry.rent.pickup_location_id,
        },
      };
      this.is_enquiry_submit_form_loading = true;
      submitRent(rent)
        .then((subResp) => {
          this.is_enquiry_submit_form_loading = false;
          if (subResp.data.state) {
            this.$router.push({
              name: "status",
              params: {
                type: "rentSubmitted",
              },
            });
          } else {
            this.error =
              "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.";
          }
        })
        .catch((error) => {
          this.is_enquiry_submit_form_loading = false;
          this.error = `Es ist ein Fehler aufgetreten. Überprüfen Sie Ihre Internetverbindung. (${error})`;
        });
    },
    getCostPositions(workshop_interest) {
      let positions = [];
      positions.push({
        name: `Miete ${this.enquiry.rent.amount_boxes}'000 Dominosteine`,
        type: "revenue",
        value: `${this.rent_price_dominoes}`,
      });
      if (this.is_portal_access_calculated) {
        let portal_text =
          this.enquiry.rent.type == "school" ? "SDE @School" : "SDE Portal";
        positions.push({
          name: `Portalzugang (${portal_text})`,
          type: "revenue",
          value: `${this.rent_price_portal}`,
        });
      }
      if (this.is_delivery_selected) {
        positions.push({
          name: `Transportkosten Lieferung (${this.enquiry.rent.distance}km)`,
          type: "expense",
          value: `${this.rent_price_transport}`,
        });
      } else if (this.is_shipping_selected) {
        positions.push({
          name: `Transportkosten Postversand`,
          type: "expense",
          value: `${this.rent_price_transport}`,
        });
      }
      if (workshop_interest) {
        positions.push({
          name: `Workshop Gage`,
          type: "revenue",
          value: `0`,
        });
      }
      if (this.is_voucher_code_present) {
        positions.push({
          name: `Vergünstigung (${this.enquiry.rent.voucher})`,
          type: "expense",
          value: `-${this.rent_price_voucher}`,
        });
      }
      return positions;
    },
    detectChange() {
      this.changed = true;
    },
    open_portal_modal() {
      this.portal_modal_shown = true;
    },
    close_portal_modal() {
      this.portal_modal_shown = false;
    },
    set_portal_access_true() {
      this.close_portal_modal();
      this.enquiry.rent.portal_access = "requested";
      this.$buefy.toast.open({
        duration: 10000,
        message: `Zugang für das Portal wurde auf 'Ja' gesetzt. Bitte berechnen Sie die Kosten erneut.`,
        position: "is-bottom",
        type: "is-primary",
      });
    },
  },
  computed: {
    rent_price_dominoes() {
      return this.is_price_available ? this.resp.data.price.rent_dominoes : 0;
    },
    rent_price_transport() {
      return this.is_price_available
        ? this.resp.data.price.transport_dominoes
        : 0;
    },
    rent_price_portal() {
      if (this.is_price_available) {
        return this.is_portal_access_calculated
          ? this.resp.data.price.portal_access
          : 0;
      }
      return 0;
    },
    rent_price_voucher() {
      if (this.is_price_available) {
        return this.resp.data.price.voucher ? this.resp.data.price.voucher : 0;
      }
      return 0;
    },
    is_voucher_code_present() {
      return this.enquiry.rent.voucher;
    },
    is_portal_access_calculated() {
      let calced_access = this.is_price_available
        ? this.resp.data.price.portal_access
        : null;
      if (calced_access) {
        return calced_access > -1;
      }
      return false;
    },
    rent_price_sum() {
      return (
        Number(this.rent_price_dominoes) +
        Number(this.rent_price_transport) +
        Number(this.rent_price_portal) -
        Number(this.rent_price_voucher)
      );
    },
    is_error_occured() {
      if (this.resp) {
        return !this.resp.state;
      }
      return false;
    },
    is_price_available() {
      return this.resp && this.resp.state && this.resp.data.price;
    },
    is_delivery_selected() {
      return this.enquiry.rent.transport == "delivery";
    },
    is_shipping_selected() {
      return this.enquiry.rent.transport == "shipping";
    },
    is_pickup_selected() {
      return this.enquiry.rent.transport == "pickup";
    },
    is_shipping_available() {
      return this.enquiry.rent.amount_boxes <= 6; //max 2 pakete mit insgesamt 6'000 dominos
    },
    rent_unavailable_error() {
      if (this.resp && this.resp.state && this.resp.data.availability) {
        let availability = this.resp.data.availability;
        if (availability.state != null && !availability.state) {
          let availableDominoes = availability.available_domino_count;
          if (availableDominoes > 0) {
            return `Leider sind an diesen Daten nur noch <b>${availableDominoes} Dominosteine</b> für eine Vermietung verfügbar.`;
          } else {
            return "Am angegebenen Zeitraum sind bereits alle Dominosteine vermietet.";
          }
        }
      }
      return null;
    },
    domino_amount_options() {
      let options = [];
      options.push({
        val: 1,
        display: `1 Box (1'000 Dominos)`,
      });
      for (let i = 2; i <= 50; i++) {
        options.push({
          val: i,
          display: `${i} Boxen (${i}'000 Dominos)`,
        });
      }

      return options;
    },
    rentEnquiryButtonDisabled() {
      return (
        this.rent_price_sum <= 0 || this.changed || this.rent_unavailable_error
      );
    },
    portalAccessChoosable() {
      return this.enquiry.rent.type != "company";
    },
    initialTypeSelection() {
      return this.$route.params.access_type;
    },
    loading_shown_for_pickup_locations() {
      return !this.pickup_locations;
    },
  },
  components: {
    FillInForm,
    PortalAd,
  },
};
</script>